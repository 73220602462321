export default function RenderProps(props:any, _renderFn?:string, _exports?:any){
    if(props.children && (_renderFn === 'render' || _renderFn == null)){
        return props.children
    }
    else if(props[_renderFn || 'render']){
        if(typeof props[_renderFn || 'render'] == 'function'){
            return props[_renderFn || 'render'](_exports || {})
        }
        else{
            return props[_renderFn || 'render']
        }
    }else{
        return null
    }
}