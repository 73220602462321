import React from "react";
import { Check, unCheck } from "../../../components/navbar/Navbar";
import "./EU.scss";

window.onpopstate = function () {
  unCheck();
};

function EU() {
  Check();

  return (
    <section className="eu_body">
      <div className="eu_container">
        <div className="eu_img1"></div>
        <p className="eu_description">
          <small>Europska unija</small>
        </p>
        <div className="eu_text">
          <p className="eu_paragraf">
            Projekt je sufinancirala Europska unija iz Europskog fonda za
            regionalni razvoj u sklopu Operativnog programa Konkurentnost i
            kohezija 2014.-2020.
          </p>
          <p>
            <i>Zajedno do fondova EU</i>
          </p>
        </div>
        <div className="eu_img2"></div>
        <p className="eu_description">
          <small>Europski strukturni i investicijski fondovi.</small>
        </p>
        <div className="eu_img3"></div>
        <p className="eu_description eu_paragraf">
          <small>
            Sadržaj publikacije/emitiranog materijala isključiva je odgovornost
            tvrke STATIM d.o.o.
          </small>
        </p>
        <h1>Inovacijski vaučeri za MSP-ove</h1>
        <div className="eu_text">
          <p className="eu_paragraf2">
            Projekt financira Europska Unija iz Europskog fonda za regionalni
            razvoj u sklopu Operativnog programa Konkurentnost i kohezija
            2014.-2020.
          </p>
          <p className="eu_paragraf2">
            <b>Naziv projekta:</b> Razvojna ispitivanja elektrčne daske Plurato
            Sailfin
          </p>
          <p className="eu_paragraf2">
            <b>Trajanje:</b> 08/10/2020 – 06/01/2021
          </p>
          <p className="eu_paragraf2">
            <b>Ukupna vrijednost projekta:</b> 64.060,00 kn
          </p>
          <p className="eu_paragraf2">
            <b>Bespovratna sredstva:</b> 44.842,00 kn
          </p>
          <p className="eu_paragraf2">
            <b>Korisnik:</b> STATIM D.O.O.
          </p>
        </div>
        <div className="eu_text">
          <p>
            <b>Opis, cilj i očekivani rezultati projekta:</b> Predmet ovog
            projekta su razvojna ispitivanja električne daske Plurato Sailfin
            koje su preduvjet za Certificiranje uređaja. Razlog prijave je
            napraviti razvojna ispitivanja koja su preduvjet za plasiranje
            proizvoda na domaće i EU tržište. EU norme znaju biti zahtjevne te
            je velika prednost dobro odrađenih razvojnih ispitivanja da se
            proizvod optimizira i da se poveća vjerojatnost da će se zadovoljiti
            norme za certifikaciju, kao i kvaliteta proizvoda u smislu njegove
            uporabe i sigurnosti za okoliš i ljudsko zdravlje. Ispitivanja koja
            će se napraviti su: ispitivanja na elektromagnetsku kompatibilnost
            (EN 301 489-1, EN 301 489-3, EN 61000-6-3, EN 61000-6-4), električnu
            sigurnost (EN 62368-1, EN 62311) i IP zaštitu (EN 60529). Aktivnosti
            iz ovog projekta će ubrzati razvoj proizvoda na način da će se
            utvrditi nepravilnosti u dizajnu kao i moguće nekompatibilnosti sa
            normama i standardima koje propisuje Europska unija te će se na taj
            način bitno skratiti put proizvoda od proizvodnje do kupca. Plurato
            Sailfin je novi proizvod u portfelju tvrtke te je novost na domaćem
            tržištu. Ovaj proizvod će, kada se nađe na tržištu, pridonijeti
            izvornim Hrvatskim proizvodima na Europskom tržištu kao i
            inovativnosti cijele Eurozone. Na određeni način, održivi razvoj
            kojem teži cijela EU ide naprijed zahvaljujući ovakvim projektima.
            Nakon završetka projekta i provedbe razvojnih istraživanja
            usklađenosti sa sigurnosnim normama steći će se preduvjeti za
            certificiranje i plasman proizvoda na tržište. Daljnje aktivnosti se
            planiraju financirati od sredstva iz tekućeg poslovanja od drugih
            proizvoda i usluga koji su u prodaji. U planu su daljnji razvoji
            novih modela daske, kao i razvoj drugih tipova električnih plovila.
            Plasmanom proizvoda na tržište i prodajom proizvoda je u planu
            proširivanje proizvodnih mogućnosti te zapošljavanje novih ljudi.
          </p>
        </div>
        <p>
          <b>Kontakt osoba:</b>
        </p>
        <div className="eu_text">
          <p className="eu_paragraf2">
            Nino Mijač; +385 91 33 77 877;{" "}
            <a className="eu_link" href="mailto:sales@plurato.com">
              sales@plurato.com
            </a>
          </p>
          <p>
            za više informacija:{" "}
            <a className="eu_link" href="https://strukturnifondovi.hr/">
              www.strukturnifondovi.hr
            </a>
          </p>
        </div>
      </div>
    </section>
  );
}

export default EU;