import { Fragment } from 'react';
import boardImage from '../../assets/images/forBeginnersAndProfessionals/plurato-sailfin-efoil-1.jpg';
import boardImage2 from '../../assets/images/forBeginnersAndProfessionals/plurato-sailfin-efoil-2.jpg';
import boardImage3 from '../../assets/images/forBeginnersAndProfessionals/plurato-sailfin-efoil-3.jpg';
import "./ForBeginnersAndProfessionals.scss";

function ForBeginnersAndProfessionals() {

    const rows = [
        { 
            title: 'For beginners and professionals',
            description: 'Suitable for a wide range of riders. Thanks to its design, Plurato Sailfin can be ridden standing on your feet, sitting or lying on the chest.',
            image: boardImage
        },
        { 
            title: 'Eco Friendly',
            description: 'No harmful emissions! Plurato Sailfin has a Li-ion battery that powers the silent electric motor, thus avoiding unnecessary harm to our planet.',
            image: boardImage2
        },
        { 
            title: 'Fly effortlessly',
            description: 'Modularity, easy assembling, private and rental driving modes, safe mode that limits the speed...',
            image: boardImage3
        }
    ]

    return (
        <section id="forBeginnersAndProfessionals">
            {rows.map(x => {
                return (<Fragment key={x.title}>
                    <div className="text_image_row">
                        <div className="text_image_text_holder">
                            <div className="text_image_title">{x.title}</div>
                            <div className="text_image_subtitle">{x.description}</div>
                        </div>
                        <div className="text_image_image_holder">
                            <img src={x.image} alt={`Plurato Sailfin eFoil board - ${x.title}`} />
                        </div>
                    </div>
                </Fragment>)
            })}

        </section>
    );
}

export default ForBeginnersAndProfessionals;
