import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import addLog from "../globals/Log";
import { isNullOrEmpty } from "../globals/Helpers";

var setUrl;
var addresCheck = process.env.REACT_APP_SERVER_ADDRESS;

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  setUrl = "http://localhost:3008/send";
} else {
  if (addresCheck === 'production'){
    setUrl = "https://sailfin.plurato.com/send";
  } else if (addresCheck === 'test') {
    setUrl = "https://sailfin.test.plurato.com/send";
  }
  else {
    console.error("Missing URL to backend");
  }   
}

export default function ContactForm2() {
  const [formData, setFormData] = useState({});
  const [showErrors, setShowErrors] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowErrors(true);
    if (
      formData &&
      !isNullOrEmpty(formData.name) &&
      !isNullOrEmpty(formData.email) &&
      !isNullOrEmpty(formData.message) &&
      validateCaptcha(formData.captcha, false)
    ) {
      setIsLoading(true);
      axios({
        method: "POST",
        url: setUrl,        
        data: formData,
      })
        .then((response) => {
          setIsLoading(false);
          if (response.data.msg === "success") {
            addLog({ success: "Message sent" });
            resetForm();
          } else {
            addLog({ error: "Message failed to send" });
          }
        })
        .catch((err) => {
          setIsLoading(false);
          console.log("Error on submit", err);
          addLog({ error: "Message failed to send" });
        });
    } else {
      addLog({ error: "Form is not valid" });
    }
  };

  const updateField = (keyVal) => {
    if (keyVal && formData) {
      let newFormData = Object.assign({}, formData);
      newFormData = Object.assign(newFormData, keyVal);
      setFormData(newFormData);
    }
  };

  const resetForm = () => {
    setFormData({ name: "", email: "", message: "", captcha: "" });
    setShowErrors(false);
    loadCaptchaEnginge(6, "transparent", "#3d9cb5");
  };

  useEffect(() => {
    loadCaptchaEnginge(6, "transparent", "#3d9cb5");
  }, []);

  return (
    <div className="contact_us_form">
      {isLoading ? <div className="lds_loader"></div> : null}
      <form
        onSubmit={handleSubmit}
        className="form_body"
        data-loading={isLoading ? "true" : null}
      >
        <div
          className="form_row"
          style={{ marginTop: 0 }}
          data-error={
            showErrors &&
            (!formData || !formData.name || formData.name.trim() === "")
              ? "Name is required"
              : undefined
          }
        >
          <input
            type="text"
            placeholder="Name"
            autoComplete="x"
            value={formData ? formData.name : undefined}
            onChange={(e) => updateField({ name: e.currentTarget.value })}
          />
        </div>
        <div
          className="form_row"
          data-error={
            showErrors &&
            (!formData || !formData.email || formData.email.trim() === "")
              ? "Email is required"
              : undefined
          }
        >
          <input
            type="email"
            placeholder="Email"
            autoComplete="x"
            value={formData && formData.email}
            onChange={(e) => updateField({ email: e.currentTarget.value })}
          />
        </div>
        <div
          className="form_row"
          data-error={
            showErrors &&
            (!formData || !formData.message || formData.message.trim() === "")
              ? "Message is required"
              : undefined
          }
        >
          <textarea
            name="message"
            placeholder="Message"
            autoComplete="x"
            value={formData && formData.message}
            onChange={(e) => updateField({ message: e.currentTarget.value })}
          ></textarea>
        </div>
        <div className="captcha_row">
          <LoadCanvasTemplateNoReload />
        </div>
        <div
          className="form_row"
          data-error={
            showErrors &&
            (!formData ||
              !formData.captcha ||
              formData.captcha.trim() === "" ||
              validateCaptcha(formData.captcha, false) !== true)
              ? "Text above not matched"
              : undefined
          }
        >
          <input
            placeholder="Repeat text above"
            type="text"
            maxLength="6"
            autoComplete="x"
            value={formData && formData.captcha}
            onChange={(e) => updateField({ captcha: e.currentTarget.value })}
          />
        </div>
        <div
          className="form_row"
          style={{ textAlign: "center", marginBottom: 0 }}
        >
          <button type="submit" className="submit_button">
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
