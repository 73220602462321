import { faFacebookF, faInstagram, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link as Select } from "react-scroll";
import picture from "../../assets/images/home/plurato-sailfin-thumbnail.png";
import heroImage from "../../assets/images/home/ride-the-air.png";
import videoWeb from "../../assets/videos/plurato-sailfin-efoil.mp4";
import useMarkVisibleElements from "../other/useMarkVisibleElements.js";
import "./Home.scss";


function Home() {
  useMarkVisibleElements(".observedVisibility");

  return (
    <section id="home">
      <video
        poster={picture}
        src={videoWeb}
        id="video"
        muted = {true}
        autoPlay = {true}
        loop = {true}
        playsInline = {true}
      />
      <div className="home--container observedVisibility">
        <div className="heroTitle"><img src={heroImage} alt="plurato sailfin efoil" /></div>
      </div>
      <div className="overlay gradient"></div>
      <Select
        to="about2"
        spy={true}
        offset={-20}
        smooth={true}
      >
        <span id="arrowDown">'</span>
      </Select>
      <div className="hero_social_icons">
        <a href="https://www.facebook.com/pluratosailfin/">
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        <a href="https://www.instagram.com/plurato_sailfin/">
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        <a href="https://www.youtube.com/channel/UCuGcsdkl9PHuIxNCdRtqTmg">
          <FontAwesomeIcon icon={faYoutube} />
        </a>
      </div>
      <div className="hero_moto">It’s not just a sport. It’s a way of life.</div>
    </section>
  );
}

export default Home;
