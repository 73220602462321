import "./importantNotice.scss";

const ImportantNotice = () => {
  return (
    <div className='notice'>
      <h2>
        Important Notice to Current and Future Users of the Plurato Sailfin
        Efoil Board
      </h2>
      <p>Dear Valued Customers,</p>
      <p>
        We are issuing an important safety notice regarding the use of the
        Plurato Sailfin efoil board. As the manufacturer, we strictly prohibit
        the use of the efoil board on boats, yachts, or similar vessels due to
        the serious risk of battery ignition caused by improper handling. If you
        choose to disregard this warning and use the efoil board in such
        environments, you do so entirely at your own risk.
      </p>
      <h2>Lithium-Ion Battery Safety:</h2>
      <ul>
        <li>
          <b>Inherent Dangers:</b> Lithium-ion batteries are highly sensitive
          and can be dangerous and flammable if mishandled. These batteries pose
          significant risks, including fire and explosion, particularly when
          they are overcharged, overheated, or physically damaged. It is crucial
          to handle these batteries with extreme care and adhere strictly to all
          safety guidelines.
        </li>
        <li>
          <b>Storage and Charging Precautions:</b>
          <ul>
            <li>
              The battery must always be stored and charged in a designated
              safety box, which is designed to mitigate the risks of fire. These
              safety boxes can be purchased from us or from other reputable
              manufacturers.
            </li>
            <li>
              Batteries should always be charged under supervision. Never leave
              the battery unattended while charging, as doing so greatly
              increases the risk of accidents.
            </li>
            <li>
              Ensure that the battery is stored in a cool, dry place, away from
              flammable materials.
            </li>
          </ul>
        </li>
        <li>
          <b>General Safety Considerations:</b>
          <ul>
            <li>
              All users must be vigilant about where and how they store their
              batteries. Proper storage and careful handling are essential to
              prevent accidents and to ensure the safety of both the user and
              others.
            </li>
          </ul>
        </li>
      </ul>
      <h2>Additional Safety Tips:</h2>
      <ol>
        <li>
          <b>Inspect Regularly:</b> Frequently check your batteries for any
          signs of damage, swelling, or leakage. Do not use damaged batteries.
        </li>
        <li>
          <b>Proper Disposal:</b> Dispose of batteries properly at designated
          recycling centers. Do not throw them in regular trash.
        </li>
        <li>
          <b>Avoid Extreme Conditions:</b> Do not expose batteries to extreme
          temperatures or direct sunlight for extended periods.
        </li>
        <li>
          <b>Use Appropriate Chargers:</b> Always use chargers recommended by
          the manufacturer to prevent overcharging and overheating.
        </li>
      </ol>
      <p>
        Your safety is our top priority. We urge you to comply with these
        guidelines to ensure the safe and responsible use of the Plurato Sailfin
        efoil board.
      </p>
      <p>
        Best regards,
        <br />
        Plurato Sailfin Team
      </p>
    </div>
  );
};

export default ImportantNotice;
