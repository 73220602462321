import React from 'react'
import ReactDOM from 'react-dom'
import RenderProps from './RenderProps'

type Import = {
    render: Plurato.Globals.RenderPropsEmpty
    dontPort?: boolean
}
const MODAL_CONTAINER_SELECTOR = 'modalBox'
export default function Portal(props:Import){
    if(props.dontPort) return <>{RenderProps(props, 'render')}</>
    try{
        let target = document.querySelector(MODAL_CONTAINER_SELECTOR)
        if(target)
            return ReactDOM.createPortal(RenderProps(props, 'render'), target)
        else
            return <>{RenderProps(props, 'render')}</>
    }
    catch(e){
        return <>{RenderProps(props, 'render')}</>
    }
}