import { Link } from "react-router-dom";
import { Link as Select } from "react-scroll";
import logo from "../../assets/images/navbar/sailfin-logo.svg";
import "./Navbar.css";

var scrollCheck = true;
var shopCheck = false;

function Check(){
  shopCheck = true;
}

function unCheck(){
  shopCheck = false;
}

function Burger() {
  document.getElementById("navbar--burger").classList.toggle("change");
  document.getElementById("menu").classList.toggle("open");
}

window.onscroll = function () {
  var about = document.getElementById("about");

  if(!shopCheck)
  {
    if (about.getBoundingClientRect().top >= -120) {
    document.getElementById("video").style.display = "block";
  }
  if (about.getBoundingClientRect().top < -120) {
    document.getElementById("video").style.display = "none";
  }
  if (window.pageYOffset >= 60) {
    document.getElementById("navbar").classList.add("navbar_effect");
    document.getElementById("toTop").style.display = "block";
    scrollCheck = false;
  }
  if (window.pageYOffset < 60) {
    document.getElementById("navbar").classList.remove("navbar_effect");
    document.getElementById("toTop").style.display = "none";
    document.getElementById("toTop").style.opacity = "20%";
    scrollCheck = true;
  }
}
};

function mouseEnter() {
  if (scrollCheck) {
    document.getElementById("navbar").classList.add("navbar_effect");
  }
}

function mouseLeave() {
  if (scrollCheck) {
    document.getElementById("navbar").classList.remove("navbar_effect");
  }
}

function toTopEnter() {
  document.getElementById("toTop").style.opacity = "80%";
}

function toTopLeave() {
  document.getElementById("toTop").style.opacity = "20%";
}

function Navbar() {
  return (
    <>
      <nav id="navbar" onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <div className="navbar--container">
          <div className="navbar--logo--container">
            <Select to="home" smooth={true} className="navbar--logo">
              <img src={logo} alt="Plurato Sailfin logo" />
            </Select>
          </div>
          <div className="navbar--menu--container">
            <ul className="navbar--menu">
              <Select activeClass="active" to="home" spy={true} smooth={true}>
                <li className="navbar--menu--item">Home</li>
              </Select>
              <Select to="features" spy={true} offset={-15} smooth={true}>
                <li className="navbar--menu--item">Features</li>
              </Select>
              <Select to="contactUs" spy={true} offset={-80} smooth={true}>
                <li className="navbar--menu--item">Contact us</li>
              </Select>
            </ul>
          </div>
          <div id="navbar--burger" onClick={Burger}>
            <div className="bar1" />
            <div className="bar2" />
            <div className="bar3" />
          </div>
        </div>
      </nav>
      <div id="menu">
        <ul className="menu--container">
          <li className="menu--item">
            <Select
              activeClass="active"
              to="home"
              spy={true}
              smooth={true}
              onClick={Burger}
            >
              Home
            </Select>
          </li>
          <li className="menu--item">
            <Select
              to="features"
              spy={true}
              offset={-64}
              smooth={true}
              onClick={Burger}
            >
              Features
            </Select>
          </li>
          <li className="menu--item">
            <Select
              to="contactUs"
              spy={true}
              offset={-80}
              smooth={true}
              onClick={Burger}
            >
              Contact us
            </Select>
          </li>
        </ul>
      </div>
      <Select
        to="home"
        spy={true}
        smooth={true}
        onMouseEnter={toTopEnter}
        onMouseLeave={toTopLeave}
      >
        <div id="toTop" />
      </Select>
    </>
  );
}

export default Navbar;
export { Check, unCheck };

