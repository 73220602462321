import { useCallback, useEffect } from "react";

var useMarkVisibleElements = function (selector, threshold, toggle) {
  if (threshold === void 0) {
    threshold = 0.2;
  }
  if (toggle === void 0) {
    toggle = false;
  }
  var inViewport = useCallback(
    function (entries, observer) {
      entries.forEach(function (entry) {
        if (toggle || entry.isIntersecting) {
          entry.target.classList.toggle("is-inViewport", entry.isIntersecting);
        }
      });
    },
    [toggle]
  );
  useEffect(
    function () {
      var Obs = new IntersectionObserver(inViewport, {
        root: null,
        rootMargin: "0px",
        threshold: threshold,
      });
      var ELs_inViewport = document.querySelectorAll(selector);
      ELs_inViewport.forEach(function (EL) {
        Obs.observe(EL);
      });
    },
    [inViewport, selector, threshold]
  );
};

export default useMarkVisibleElements;
