
import About from "../../../components/about/About";
import ContactUs from "../../../components/contact/ContactUs";
import Features from "../../../components/features/Features";
import FeelTheRush from "../../../components/feelTheRush/FeelTheRush";
import Footer from "../../../components/footer/Footer";
import ForBeginnersAndProfessionals from "../../../components/ForBeginnersAndProfessionals/ForBeginnersAndProfessionals";
import Home from "../../../components/home/Home";
import ImportantNotice from "../../../components/importantNotice/ImportantNotice";
import Modal from "../../../components/modal/Modal";
import Navbar from "../../../components/navbar/Navbar";
import RentingAndTeamBuilding from "../../../components/rentingAndTeamBuilding/RentingAndTeamBuilding";
import { useEffect, useState } from "react";

function Index() {
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    return setTimeout(() => { setOpenModal(true) }, 5000)
  }, [])

  return (
    <>
      {openModal && <Modal render={ImportantNotice} modalBoxClass={undefined} onClose={() => setOpenModal(false)} />}
      <Navbar />
      <Home />
      <About />
      <FeelTheRush />
      <Features />
      <ForBeginnersAndProfessionals />
      <RentingAndTeamBuilding />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Index;
