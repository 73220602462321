import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";

import Index from "./assets/pages/index/Index";
import EU from "./assets/pages/eu/EU.tsx";

class App extends Component {

  render(){

  return (
    <>
      <Router>
        <Switch>
          <Route path="/" exact component={Index} />
          <Route path="/eu" component={EU} />
        </Switch>   
      </Router>
      <div id='modalBox'></div>
      <div id='feedbackWindow'>
      </div>
    </>
  );
}
}

export default App;
