import { Fragment } from 'react';
import boardImage from '../../assets/images/about/baby_blue.png';
import "./Features.scss";

function Features() {

    const featureList = [
        { title: "Engine", value: "4.8 kW" },
        { title: 'Top Speed', value: '36 Km/h' },
        { title: "Battery Life", value: "60-80min" },
        { title: "Charging Time", subtitle: 'up to', value: "120min" },
        { title: 'Regular Board', value: '6’9’’' },
        { title: 'Pro Board', value: '5’8’’' }
    ]

    return (
        <section id="features">
            <div className="features_section">
                <div className="features_text_section">
                    <div className="features_section_title">Plurato Sailfin</div>
                    <div className="features_section_subtitle">We take pride in the outstanding design, durable materials and functionality of our board, creating the experience of flying over water</div>

                    <div className="features_grid">
                        {featureList.map(x => {
                            return (<Fragment key={x.title}>
                                <div className="feature_box">
                                <div className="feature_box_title">
                                    {x.title}<br/>
                                    {x.subtitle && <div className="feature_box_subtitle">{x.subtitle}</div>}
                                </div>
                                <div className="feature_box_value">{x.value}</div>
                            </div>
                            </Fragment>)
                        })}
                    </div>
                </div>
                <div className="features_image_holder">
                    <img src={boardImage} alt="Plurato Sailfin eFoil board" />
                </div>
            </div>

        </section>
    );
}

export default Features;
