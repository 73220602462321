import feelTheRushImage from "../../assets/images/feelTheRush/plurato-sailfin-efoil-feel-the-rush-of-e-foiling.png";
import "./FeelTheRush.scss";

function FeelTheRush() {
  return (
    <>
        <section id="feelTheRush">
            <div className="feel_the_rush_image_holder">
                <div className="feel_the_rush_title">Feel the rush</div>
                <img src={feelTheRushImage} alt="Plurato Sailfin eFoil feel the rush of e foiling" />
            </div>
        </section>
    </>
  );
}

export default FeelTheRush;
