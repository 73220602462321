import React from 'react'
import ContactForm from './ContactForm'
import './ContactUs.scss'
import logo from '../../assets/images/contact/plurato_logo.png'

export default function ContactUs2(){
    return (
        <section id='contactUs'>
            <div className='contact_us_logo'><img src={logo} alt='Plurato Logo' /></div>
            <div className='contact_us_title'>
                Want to buy or try Plurato Sailfin, or just have a question?
            </div>
            <div className='contact_us_text'>
                Hit Us Up! We look forward to hearing from you <span className='emoji'>😊</span>!
            </div>

            <ContactForm />

        </section>       
    )
}