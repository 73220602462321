import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect } from 'react'
import { useState } from 'react'
import Portal from '../globals/Portal'
import RenderProps from '../globals/RenderProps'
import './Modal.scss'

type Import = {
    render: Plurato.Globals.RenderPropsEmpty
    modalBoxClass?: 'small_modal'|undefined
    onClose: () => {}
}
export default function Modal(props: Import){
    const [ animationClass, setAnimationClass ] = useState('slide_from_bottom')
    const handleClose = () => {
        setAnimationClass('slide_down_out')
        setTimeout(() => {
            document.body.style.overflow = 'unset';
            document.body.style.touchAction = 'unset';
            props.onClose()
        }, 300)
    }

    useEffect(() => {
        document.body.style.overflow = 'hidden';
        document.body.style.touchAction = 'none';
    }, [])

    return  <Portal render={(
        <div className={'modal_backdrop'}>
            <div className={`modal_box ${animationClass} ${props.modalBoxClass || ''}`}>
                <div className={'close_button'} onClick={handleClose}>
                    <FontAwesomeIcon icon={faTimes} />
                </div>
                { props.render && RenderProps(props, 'render') }
            </div>
        </div>
    )} />
}