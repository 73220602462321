import aboutBoardImage from '../../assets/images/about/plurato-sailfin-efoil-bw-bars.png';
import "./About.scss";

function About() {

  return (
    <>
        <section id="about">
            <div className="about_row">
                <div className="about_row_text_holder">
                    <div className="about_row_title">Let's get you flying!</div>
                    <div className="about_row_subtitle">
                        Are you looking to raise your game a little? Whether you’re an adventurer, sports enthusiast or an early adopter eternally searching for new thrills we've got you covered. With our <span>Plurato Sailfin</span>, chase that magical feeling while the board cuts through the water. Once you experience the feeling, you’re hooked, there’s no coming back...
                        <div className='highlight_text'>It’s incredible! It’s addictive!</div>
                    </div>
                </div>
                <div className="about_row_image_holder">
                    <img src={aboutBoardImage} alt={`Plurato Sailfin eFoil board`} />
                </div>
            </div>
        </section>
    </>
  );
}

export default About;
