import { Fragment } from 'react';
import "./RentingAndTeamBuilding.scss";
import waveImage from '../../assets/images/rentingAndTeamBuildings/wave_image.png';

function RentingAndTeamBuilding() {

    return (
        <section id="rentingAndTeamBuildings">
            <img src={waveImage} alt={"Plurato Sailfin is perfect for adventure, renting and teambuildings"} />
            <div className='renting_text_section'>
                <div className='renting_text_section_title'>Adventure? Renting? Teambuildings? It's perfect!</div>
                <div className='renting_text_section_content'>
                    Enjoy the ride anywhere you want. Want to try 
                    Plurato Sailfin at your business? Are you a renter 
                    on an attractive beach? Become our exclusive partner 
                    for rental and eFoil school, with special terms!
                </div>
            </div>
        </section>
    );
}

export default RentingAndTeamBuilding;