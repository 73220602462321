type Import = {
    success: string
    error: string
}
export default function addLog(log: Import){
    let newElement = document.createElement('div')
    newElement.innerHTML = log.success || log.error
    
    if(log.success){
        newElement.className = 'feedback_message success_log'
    }
    else{
        newElement.className = 'feedback_message error_log'
    }

    newElement.onclick = function(el){
        newElement.remove()
    }

    setTimeout(() => {
        newElement.remove()
    }, 5000)
    document.getElementById("feedbackWindow")?.append(newElement)
}